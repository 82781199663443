<template>
  <el-form class="form" ref="mainFrom" :model="formData" :rules="rules" size="medium" label-width="100px" v-if="loaded">
    <el-row :gutter="15">
      <el-col :span="24">
        <el-form-item label="标题" prop="Headline">
          <el-input v-model="formData.Headline" placeholder="请输入招聘简讯标题" :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :span="24">
        <el-form-item label="项目班" prop="LayoutId">
          <el-select class="mr-10" v-model="formData.LayoutId" style="width:100%" filterable>
            <el-option v-for="item in projectClassList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :span="24">
        <el-form-item label="摘要" prop="Summary">
          <el-input v-model="formData.Summary" placeholder="请输入摘要">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :span="24">
        <el-form-item label="主要内容" prop="Content">
          <rich-text ref="Content" name="Content" :value="formData.Content" @input="updateForm"></rich-text>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :span="12">
        <el-form-item label="发布时间" prop="ReleaseTime">
          <el-date-picker v-model="formData.ReleaseTime" @change="datechange" type="datetime" format="yyyy-MM-dd HH:mm" :style="{ width: '100%' }" placeholder="请输入发布时间" clearable></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :span="24">
        <el-form-item size="large">
          <el-button type="primary" @click="submitForm" :loading="submitting">发布</el-button>
          <el-button @click="cancle">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { formartDate } from "@/utils/tools";
import { createEntity, updateEntity, getInfo } from "@/api/recruitnews";
import contentTools from "@/mixins/content-tools";
import RichText from "@/components/base/RichText";
// import { getEnterpriseAndStaffByUserId } from "@/api/enterprise/enterpriseStaff";
import { getAllList as getProjectClassList } from "@/api/internshipLayout";
import { mapGetters } from "vuex";
import { StaffTypeEnum } from "@/utils/constant";

export default {
  components: { RichText },
  mixins: [contentTools],
  props: [],
  data() {
    return {
      id: "",
      formData: {
        Headline: "",
        Content: "",
        ReleaseTime: "",
        EnterpriseId: "",
        Summary: "",
        LayoutId: ""
      },
      submitting: false,
      rules: {
        Headline: [{ required: true, message: "请输入标题", trigger: "blur" }],
        LayoutId: [
          { required: true, message: "请选择项目班", trigger: "change" }
        ],
        Content: [{ required: true, message: "请输入标题", trigger: "blur" }],
        ReleaseTime: [
          { required: true, message: "请选择发布时间", trigger: "change" }
        ]
      },
      // enterPriseId: "",
      loaded: false,
      projectClassList: []
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    EnterpriseId({ employee = [] }) {
      const { Employer } =
        employee.find(item => item.StaffType === StaffTypeEnum.Enterprise) ||
        {};
      if (Employer) {
        return Employer.Id;
      }
      return null;
    }
  },
  watch: {},
  created() {
    this.id = this.$route.query.id || "";
    this.initialize();
    // console.log(`object`, this.EnterpriseId)
  },
  mounted() { },
  methods: {
    async initialize() {
      this.loaded = false;
      // var resEnterprise = await getEnterpriseAndStaffByUserId(
      //   this.$store.getters.userId
      // );
      // if (resEnterprise.IsSuccess && resEnterprise.Result.Enterprise) {
      //   this.enterPriseId = resEnterprise.Result.Enterprise.Id;
      // } else {
      //   this.$router.push("/");
      // }
      try {
        console.log(`this`, this.EnterpriseId);
        // 查看和编辑时需要获取详情
        await this.getProjectClassList(this.EnterpriseId);
        if (this.id) {
          const res = await getInfo(this.id);
          if (res.IsSuccess) {
            this.formData = res.Result;
          }
        } else {
          this.formData.ReleaseTime = this.getNowDate();

          console.log("formData:", this.formData);
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
      this.loaded = true;
    },
    submitForm() {
      this.$refs.mainFrom.validate(async valid => {
        if (valid) {
          this.isUploading = true;
          let isSuccess = false;
          let message = "服务器错误！";
          this.formData.EnterpriseId =
            this.formData.EnterpriseId || this.EnterpriseId; // this.enterPriseId;
          var postData = Object.assign({}, this.formData);
          this.submitting = true;
          try {
            if (this.id) {
              const res = await updateEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            } else {
              const res = await createEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            }
            if (isSuccess) {
              this.$message.success(message || "保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isUploading = false;
            this.submitting = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误"
          });
          return;
        }
      });
    },
    updateForm(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.formData[fieldName] = value;
    },
    cancle() {
      if (!this.isView) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "温馨提示"
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.$router.go(-1);
      }
    },
    async getProjectClassList(enterpriseId) {
      const { Result = [] } = await getProjectClassList(enterpriseId);
      this.projectClassList = Result;
    },
    datechange(val) {
      this.formData.ReleaseTime = formartDate(val, "all");
    }
  }
};
</script>
<style lang="scss" scoped>
.form {
  background: #fff;
  padding: 48px;
  margin-bottom: 20px;
  border-radius: 8px;
}
</style>
